
.store-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #FFF;
}
.store-container {
    /*min-width: 1200px;*/
    height: 100vh;
    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            /*max-width: 1200px;*/
            min-width: 1200px;
        }
    }
    .store-dianzhao {
        margin: 0 auto;
        width: 950px;
        height: 120px;
        //padding-bottom: 6.25%;
        position: relative;
        background: #fafafa;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
        }
    }
    .store-tab-wrapper {
        height: 30px;
        background: #fff;
        .store-tab {
            width: 950px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item {
                color: #666;
                transition: all .3s;
                position: relative;
                line-height: 30px;
                .class-levelOne {
                    padding: 0 30px;
                    cursor: pointer;
                    i {
                        margin-left: 6px;
                    }
                    &:hover {
                        color: #FF0000;
                    }
                }
                .class-levelTwo {
                    display: none;
                    position: absolute;
                    top: 29px;
                    left: 25px;
                    /*right: 30px;*/
                    z-index: 99;
                    background: #fff;
                    border: 1px solid #000;
                    transition: all .3s;
                    min-width: 80px;
                    .item-two {
                        cursor: pointer;
                        line-height: 24px;
                        padding: 0 10px;
                        margin-top: 5px;
                        transition: all .3s;
                        white-space: nowrap;
                        &:first-child {
                            margin-top: 0;
                        }
                        &:hover {
                            background: #eee;
                        }
                    }
                }
                &:hover {
                    .class-levelTwo {
                        display: block;
                    }
                }
            }
        }
    }
    .store-banner {
        width: 950px;
        height: 250px;
        position: relative;
        .item {
            background: #fff;
            .img-cover {
              width: 100%;
              height: 100%;
              //padding-bottom: 32.8125%;
              position: relative;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
        .topic-swiper-button-prev, .topic-swiper-button-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, .5);
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            outline: none;
            z-index: 99;
            border-radius: 50%;
            .iconfont {
                font-size: 30px;
                display: block;
                color: #fff;
                transition: all .3s;
            }
            &:hover {
                .iconfont {
                    color: #ff0000;
                }
            }
        }
        .topic-swiper-button-prev {
            left: 80px;
        }
        .topic-swiper-button-next {
            right: 80px;
        }
    }
    .store-goods {
        width: 950px;
        margin: 16px auto 0;
        .good-head {
            position: relative;
            display: flex;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: #E5E5E5;
            }
            .item {
                z-index: 1;
                height: 30px;
                font-size: 14px;
                padding: 0 12px;
                line-height: 30px;
                color: #666;
                margin-right: 10px;
                border: 1px solid #DCDCDC;
                transition: all .3s;
                cursor: pointer;
                &.current {
                    color: #1122D8;
                }
                &:hover {
                    border-color: #1122D8;
                }
            }
        }
        .goods-list {
            display: flex;
            flex-wrap: wrap;
            //margin: 0 -25px 0;
            .good-item {
                /*width: calc(25% - 66.666666px);*/
                width: 180px;
                //margin: 20px 25px;
                margin: 20px 0 20px 66.666666px;
                &:nth-of-type(4n + 1) {
                    margin-left: 0;
                }
                .good-cover {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    position: relative;
                    display: block;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .good-content {
                    .good-title {
                        margin-top: 20px;
                        color: #333;
                        transition: all .3s;
                        display: block;
                        &:hover {
                            color: #FF0000;
                        }
                    }
                    .good-price {
                        margin-top: 20px;
                        color: #ff0000;
                        .symbol {
                            font-size: 12px;
                            margin-right: 4px;
                            font-style: normal;
                        }
                        .price {
                            font-weight: bold;
                            font-size: 16px;
                        }
                    }
                    .good-comment {
                        margin-top: 12px;
                        color: #666;
                    }
                    .good-btn {
                        margin-top: 12px;
                        .el-button {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
    /deep/ .pager-center {
        .el-pager li {
            background: transparent;
        }
        button:disabled {
            background-color: transparent;
        }
        .btn-next, .btn-prev {
            background: center center no-repeat transparent;
        }
    }
}
/deep/ .tab-search {
    .el-input__inner {
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
    }
    .el-input__icon {
        line-height: 24px;
    }
    &.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: #ff0000;
        outline: 0;
    }
}
/deep/ p {
    margin: 0;
}
